/* Font faces for NeueHaasGrotesk */
// @font-face {
//   font-family: NeueHaasGrotesk;
//   src: url(./fonts/neue-haas-grotesk/NeueHaasDisplayMediu.ttf);
//   font-weight: 500;
// }

// @font-face {
//   font-family: NeueHaasGrotesk;
//   src: url(./fonts/neue-haas-grotesk/NeueHaasDisplayRoman.ttf);
//   font-weight: 400;
// }

// @font-face {
//   font-family: NeueHaasGrotesk;
//   src: url(./fonts/neue-haas-grotesk/NeueHaasDisplayBold.ttf);
//   font-weight: 600;
// }

@font-face {
  font-family: Poppins;
  src: url(./fonts/poppins/Poppins-SemiBold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(./fonts/poppins/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(./fonts/poppins/Poppins-Bold.ttf);
  font-weight: 600;
}
